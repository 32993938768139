<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-navigation-drawer v-model="drawer" permanent width="100%">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-light text-h5">{{ $t('backoffice.title') }}</v-list-item-title>
          <v-list-item-subtitle class="text-body-2 font-alt">{{ $t('backoffice.subTitle') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item two-line exact link :to="{name: 'establishments-list'}">
        <v-list-item-avatar size="54">
          <font-awesome-icon :icon="['fad', 'clinic-medical']" size="2x" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-light text-subtitle-1 font-weight-light">Etablissements</v-list-item-title>
          <v-list-item-subtitle class="text-body-2 font-alt">Gérer les établissements</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <div class="pa-3">
        <v-list-item-title class="mb-2 primary--text text-subtitle-2">Choisir un établissement :</v-list-item-title>
        <v-select v-model="establishment" label="Etablissement" color="primary" outlined dense hide-details="auto" :items="allEstablishments"
                  item-text="label" item-value="id" return-object
        />
      </div>
      <v-list dense :disabled="!establishment" :class="!establishment ? 'o-30 lighten-3 pt-0' : ''">
        <v-divider />
        <template v-for="(menuItem, index) in menuType">
          <v-list-item :key="index" two-line exact link :to="{name: menuItem.link}">
            <v-list-item-avatar size="48">
              <font-awesome-icon :icon="['fad', menuItem.icon]" size="lg" class="primary--text" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-light text-subtitle-1 font-weight-light">{{ menuItem.title }}</v-list-item-title>
              <v-list-item-subtitle class="text-body-2 font-alt">{{ menuItem.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  import { mapActions, mapState} from 'vuex'
  export default {
    name: 'AdminSideBar',
    data () {
      return {
        drawer: true,
        menuType: [
          {
            title: 'Accueil',
            subtitle: 'Résumé et statistiques',
            icon: 'chart-pie-alt',
            link: 'dashboard'
          },
          {
            title: 'Utilisateurs',
            subtitle: 'Gérer les utilisateurs',
            icon: 'users',
            link: 'users-list'
          },
          {
            title: 'Services',
            subtitle: 'Gérer les services',
            icon: 'hand-holding-medical',
            link: 'services-list'
          },
          {
            title: 'Professionnels',
            subtitle: 'Gérer les professionnels',
            icon: 'user-md',
            link: 'professionals-list'
          },
          {
            title: 'Spécialités',
            subtitle: 'Gérer les spécialités',
            icon: 'book-user',
            link: 'specialities-list'
          },
          {
            title: 'Formulaires',
            subtitle: 'Gérer les formulaires',
            icon: 'cabinet-filing',
            link: 'forms-list'
          },
          {
            title: 'Questionnaires',
            subtitle: 'Gérer les questionnaires',
            icon: 'poll-h',
            link: 'surveys-list'
          }
          // {
          //   title: 'Import',
          //   subtitle: 'Importer des professionnels ou des services',
          //   icon: 'file-upload',
          //   link: 'import'
          // }
        ]
      }
    },
    computed: {
      ...mapState('establishments', ['allEstablishments']),
      establishment: {
        get(){
          return this.$store.getters['establishments/activeEstablishment']
        },
        set(establishment) {
          this.$store.dispatch('establishments/setActiveEstablishmentId', establishment.id)
        }
      }
    },
    created() {
      this.getEstablishments()
    },
    methods: {
      ...mapActions('establishments', ['getEstablishments'])
    }
  }
</script>
