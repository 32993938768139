<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <admin-side-bar />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <router-view v-if="$route.name === 'establishments-list' || activeEstablishment" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AdminSideBar from '@/modules/backoffice/components/AdminSideBar'
  import {mapGetters} from 'vuex'

  export default {
    name: 'AdminSection',
    components: {AdminSideBar},
    computed: {
      ...mapGetters('establishments', ['activeEstablishment'])
    }
  }
</script>
